<template>
  <div class="exchange">
    <van-image
      fit="cover"
      class="exchange-bg"
      :src="require('../../static/images/v3/bg_huodong.png')"
    />

    <div class="exchange-content">
      <div class="exchange-panel">
        <van-row class="">
          <van-col span="24" class="input-mobile">
            <van-field
              v-model="mobile"
              class="mobile-field"
              maxlength="11"
              size="large"
              input-align="center"
              placeholder="请输入手机号"
            />
          </van-col>
        </van-row>

        <van-row>
          <van-col span="24" class="exchange-submit">
            <!-- <van-button class="exchange-submit-btn" round color="linear-gradient(to right, #ff6034, #ee0a24)" @click="handleGet">
                立即兑换
              </van-button> -->
            <van-image
              fit="cover"
              class="exchange-submit-btn"
              :src="require('../../static/images/v3/btn.png')"
              @click="handleGet"
            />
          </van-col>
        </van-row>
      </div>
    </div>

    <div class="exchange-guize">
      <van-image
        fit="cover"
        class="exchange-guize-tip"
        :src="require('../../static/images/v3/bt_guize.png')"
      />
    </div>

    <div class="exchange-rules">
      <div class="exchange-rules-panel">
        <div class="exchange-rules-panel-title">SOFO优惠券使用说明</div>
        <div class="exchange-rules-info">
          ①扫描设备码，选择需要购买的套餐<br />
          ②手动选择您想要抵扣的优惠券，完成支付即可<br />
          <span class="exchange-rules-info-tip">
            注：1笔订单仅限使用1个优惠券包，不可叠加使用。可兑换积分越多，兑换券包面额越高，10元封顶
          </span>
          <van-image
            fit="cover"
            class="exchange-rules-info-img"
            :src="require('../../static/images/v3/intro01.png')"
          /><br />
          <!-- <van-image
            fit="cover"
            class="exchange-rules-info-img"
            :src="require('../../static/images/v3/intro02.png')"
          /> -->
          <br />
        </div>
      </div>
    </div>

    <div class="exchange-rules">
      <div class="exchange-rules-panel">
        <div class="exchange-rules-panel-title">活动规则</div>
        <div class="exchange-rules-info">
          1.本活动需使用移动积分进行兑换。积分兑换规则以移动官方细则为准。如对移动兑换细则有疑问，请联系客服020-23835222。<br />
          2.仅支持中国移动用户参与，其中河南、江西、辽宁、重庆、广西地区不支持兑换。<br />
          3.本活动兑换后不退不换，SOFO优惠券包7天内有效，逾期失效，需重新兑换。<br />
          4.成功兑换后，SOFO优惠券包直接发放到您的账户中，您可以直接使用优惠券包在当前设备进行消费抵扣。<br />
          5.暂不支持开具发票。<br />
          6.如果用户存在违规行为（包括但不限于洗钱、虚假交易、恶意套现），SOFO将取消用户的兑换资格、并有权撤销相关违规交易。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Row, Col, Field, Button, Image } from "vant";
import { authLogin } from "@/api/global";

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Field.name]: Field,
    [Button.name]: Button,
    [Image.name]: Image,
  },
  data() {
    return {
      mobile: "",
    };
  },
  created() {},
  methods: {
    handleGet() {
      if (this.mobile.length != 11) {
        this.$toast("请输入合法的手机号");
        return;
      }

      authLogin({
        mobile: this.mobile,
      }).then((res) => {
        console.log(res);
        localStorage.setItem("dd_login_token", res.loginToken);
        localStorage.setItem("dd_mobile", res.mobile);

        let query = {}
         if (this.$route.query.channelCode) {
          query.channelCode = this.$route.query.channelCode
        }

        if (this.$route.query.id) {
          query.id = this.$route.query.id
        }

        if (this.$route.query.ddback) {
          query.ddback = this.$route.query.ddback
        }

        this.$router.push({
            path: "/info",
            query
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.exchange {
  position: relative;
  min-height: 100vh;
  padding-bottom: 10px;
  background-color: #ff220c;

  &-bg {
    width: 100%;
    min-height: 400px;
    // height: 100vh;
  }

  &-content {
    position: relative;
    // background-color: rgba(255, 255, 255, 0.5);
    margin: 0px 20px;
    padding: 0px 0px 10px;
    border-radius: 10px;
    z-index: 99;
  }

  &-guize {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;

    &-tip {
      width: 85%;
      margin-bottom: 10px;
    }
  }

  &-rules {
    position: relative;
    background-color: rgba(255, 255, 255, 1);
    margin: 20px;
    padding: 10px 5px;
    border-radius: 15px;
    z-index: 99;

    &-panel {
      background-color: #fff;
      border-radius: 10px;
      padding: 10px 10px 10px;

      &-title {
        width: 100%;
        font-weight: 500;
        text-align: center;
      }
    }

    &-info {
      padding-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #333;
      line-height: 160%;

      &-tip {
        font-size: 13px;
        color: #666;
      }

      &-img {
        width: 96%;
        padding-left: 2%;
        padding-top: 10px;
        padding-bottom: 0px;
      }
    }
  }

  &-panel {
    // background-color: #fff;
    border-radius: 10px;
  }

  &-tip {
    width: 150px;
    margin-left: 50%;
    padding-bottom: 10px;
    transform: translateX(-50%);
  }

  &-submit {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  &-submit-btn {
    width: 50%;
    padding-top: 10px;
    -webkit-animation-name: scaleDraw; /*关键帧名称*/
    -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
    -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
    -webkit-animation-duration: 2s; /*动画所花费的时间*/
  }
}

.input-mobile {
  width: 90%;
  height: 48px;
  margin: 0 5%;
  border-radius: 30px;
  // box-shadow: 0px 2px 8px #ddd;
  overflow: hidden;
}

.mobile-field {
  height: 48px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
}

@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}
</style>
